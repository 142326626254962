import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DefaultTheme } from 'styled-components';

import { darkTheme, lightTheme } from '../../theme';
import { ThemeOptions } from '../components/account/user-preferences-modal/pages/theme';
import { GlobalContext } from '../context/global-context';
import { useTenant } from '../context/tenant-context';
import {
  getUser,
  InviteUserParams,
  inviteUsers,
  resendVerificationEmail,
  updateUser,
  updateUserProfile,
  UserProfileParams,
  getDemoToken,
  createUserCustomView,
  updateUserCustomView,
  deleteUserCustomView,
} from '../services/accounts';
import { getViews } from '../services/tenants';
import { isDemoApp } from '../utils/app';
import { CR_USER_PROP, TokenUtil } from '../utils/token';

import {
  CRUser,
  UserPreferences,
  UserPreferencesSavedViews,
  AnalyzeSavedView,
  MonitorSavedView,
  ViewType,
  PersistView,
  DeleteView,
} from '@controlrooms/models';

const systemTheme =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? darkTheme
    : lightTheme;

export const useUserId = () => {
  const { user, isAuthenticated } = useAuth0();
  const { demoAccessToken } = useContext(GlobalContext);
  const isDemo = isDemoApp(location.pathname);
  if (isDemo && demoAccessToken) {
    const extracted = TokenUtil.extractUserProps(demoAccessToken);
    return extracted?.user_id;
  }
  if (!isAuthenticated) return;
  return user?.[CR_USER_PROP]?.user_id;
};

const getUserPropsKey = (userId: number) => {
  return ['user-properties', userId];
};

const getUserViewsPropsKey = (userId: number) => {
  return ['user-views', userId];
};

// Version the preferences in case the format changes in the future
const DEFAULT_USER_PREFERENCES = { ['@version']: 1 } as UserPreferences;
const DEFAULT_SAVED_VIEWS = {
  [ViewType.MONITOR]: {},
  [ViewType.ANALYZE]: {},
} as UserPreferencesSavedViews;

const _useUserSelect = <T>(select?: (u: CRUser) => T) => {
  const userId = useUserId();
  const key = getUserPropsKey(userId);
  return useQuery(key, () => getUser().then(({ result }) => result), {
    enabled: Boolean(userId),
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 60 * 60 * 1000, // 1 hour,
    select,
  });
};

export const useUser = () => _useUserSelect<CRUser>();

export const useDemoToken = () => {
  return useQuery('demoToken', getDemoToken);
};

export const useTheme = (): [DefaultTheme, ThemeOptions] => {
  const { data: themeName, isSuccess } = _useUserSelect((u) => u.preferences?.theme);
  if (!isSuccess) return [darkTheme, ThemeOptions.DARK];

  switch (themeName) {
    case ThemeOptions.SYSTEM:
      return [systemTheme, ThemeOptions.SYSTEM];
    case ThemeOptions.LIGHT:
      return [lightTheme, ThemeOptions.LIGHT];
    case ThemeOptions.DARK:
    default:
      return [darkTheme, ThemeOptions.DARK];
  }
};

export const useSavedViews = (viewType: ViewType) => {
  const { tenant } = useTenant();
  return _useUserSelect<AnalyzeSavedView[] | MonitorSavedView[]>((u) => {
    const viewTypeMap = u.preferences?.views ?? DEFAULT_SAVED_VIEWS;
    const viewMap = viewTypeMap[viewType] ?? {};
    return Object.values(viewMap).filter(({ tenant: viewTenant }) => viewTenant === tenant);
  });
};

export const useGetViews = (viewType: ViewType) => {
  const key = getUserViewsPropsKey(useUserId());
  return useQuery(key, getViews, {
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 60 * 60 * 1000, // 1 hour,
    // filter based on view type and shared status and take only view object
    select: (views: PersistView[]) =>
      Object.values(views)
        .filter((v) => v.view.type === viewType && v.shared === false)
        .map((v) => {
          const view = v.view as MonitorSavedView | AnalyzeSavedView;
          // view.id = v.view_id || '';
          return view;
        }),
  });
};

export const useMutateSavedViews = () => {
  const { mutateAsync: createCustomView } = useSaveCustomView();
  const { mutateAsync: editCustomView } = useEditCustomView();
  const { mutateAsync: deleteCustomView } = useDeleteCustomView();
  const currentUserId = useUserId();

  const saveView = (view: MonitorSavedView | AnalyzeSavedView, isEditMode?: boolean) => {
    if (isEditMode) {
      const view_id = view.id;
      // delete view.id;
      const customView = {
        view,
        view_id,
      } as PersistView;
      return editCustomView(customView);
    } else {
      const customView = {
        user_id: currentUserId,
        shared: false,
        view,
      } as PersistView;
      return createCustomView(customView);
    }
  };

  const deleteView = (view: MonitorSavedView | AnalyzeSavedView) => {
    const deleteView = {
      view_id: view.id,
      user_id: currentUserId,
    } as DeleteView;
    return deleteCustomView(deleteView);
  };

  return { saveView, deleteView };
};

export const useUpdateUserPreferences = () => {
  const userId = useUserId();
  const queryClient = useQueryClient();
  const key = getUserPropsKey(userId);

  return useMutation(
    (preferences: Pick<UserPreferences, 'theme' | 'views'>) => {
      const cachedUser = queryClient.getQueryData<CRUser>(key);

      const mergedPreferences = {
        ...(cachedUser?.preferences ?? DEFAULT_USER_PREFERENCES),
        ...preferences,
      };
      return updateUser({ preferences: mergedPreferences });
    },
    {
      onMutate: async (newPreferences: Partial<UserPreferences>) => {
        await queryClient.cancelQueries(key);
        const previousPreferences =
          queryClient.getQueryData<CRUser>(key)?.preferences ?? DEFAULT_USER_PREFERENCES;
        queryClient.setQueryData<CRUser>(
          key,
          (previousUser) =>
            ({
              ...previousUser,
              preferences: {
                ...previousUser?.preferences,
                ...newPreferences,
              },
            } as CRUser),
        );
        return { previousPreferences };
      },
      onError: (_err, _newPreferences, context) => {
        context &&
          queryClient.setQueryData<CRUser>(
            key,
            (previousUser) =>
              ({
                ...previousUser,
                preferences: context.previousPreferences,
              } as CRUser),
          );
      },
    },
  );
};

export const useSaveCustomView = () => {
  const userId = useUserId();
  const queryClient = useQueryClient();
  const key = getUserViewsPropsKey(userId);

  return useMutation(
    (view: PersistView) => {
      return createUserCustomView(view);
    },
    {
      // onMutate: async (view: PersistView) => {
      //   await queryClient.cancelQueries(key);
      //   const previousViews = queryClient.getQueryData<PersistView[]>(key);

      //   console.log('cacheViews', previousViews);
      //   // add new view to cacheViews
      //   if (previousViews) {
      //     previousViews.push(view);
      //     queryClient.setQueryData<PersistView[]>(key, previousViews);
      //   }
      //   return { previousViews };
      // },
      // onError: (err, newView, context) => {
      //   if (context?.previousViews) {
      //     // Rollback to the previous state if mutation fails
      //     queryClient.setQueryData<PersistView[]>(key, context.previousViews);
      //   }
      // },
      onSuccess(result, view) {
        const previousViews = queryClient.getQueryData<PersistView[]>(key);
        const v = view.view as MonitorSavedView | AnalyzeSavedView;
        v.id = result.result;
        previousViews?.push(view);
        previousViews && queryClient.setQueryData<PersistView[]>(key, previousViews);
        queryClient.invalidateQueries(key);
      },
    },
  );
};

export const useEditCustomView = () => {
  const userId = useUserId();
  const queryClient = useQueryClient();
  const key = getUserViewsPropsKey(userId);

  return useMutation(
    (view: PersistView) => {
      return updateUserCustomView(view);
    },
    {
      onMutate: async (view: PersistView) => {
        await queryClient.cancelQueries(key);
        const previousViews = queryClient.getQueryData<PersistView[]>(key);
        // find specifi view by id
        if (previousViews) {
          const index = previousViews.findIndex((v) => v.view_id === view.view_id);
          previousViews[index] = view;
          queryClient.setQueryData<PersistView[]>(key, previousViews);
          queryClient.invalidateQueries(key);
        }

        return { previousViews };
      },
      onError: (err, newView, context) => {
        if (context?.previousViews) {
          // Rollback to the previous state if mutation fails
          queryClient.setQueryData<PersistView[]>(key, context.previousViews);
        }
      },
    },
  );
};

// create useDeleteCustomView
export const useDeleteCustomView = () => {
  const userId = useUserId();
  const queryClient = useQueryClient();
  const key = getUserViewsPropsKey(userId);

  return useMutation(
    (view: DeleteView) => {
      return deleteUserCustomView(view);
    },
    {
      onMutate: async (view: DeleteView) => {
        await queryClient.cancelQueries(key);
        const previousViews = queryClient.getQueryData<PersistView[]>(key);

        // remove view from cacheViews
        if (previousViews) {
          const index = previousViews.findIndex((v) => v.view_id === view.view_id);
          previousViews.splice(index, 1);
          queryClient.setQueryData<PersistView[]>(key, previousViews);
        }
        return { previousViews };
      },
      onError: (err, newView, context) => {
        if (context?.previousViews) {
          // Rollback to the previous state if mutation fails
          queryClient.setQueryData<PersistView[]>(key, context.previousViews);
        }
      },
    },
  );
};

export const useSetUserTermsAccepted = () => {
  const userId = useUserId();
  const queryClient = useQueryClient();
  const key = getUserPropsKey(userId);

  return useMutation((accepted: boolean) => updateUser({ accepted_terms: accepted }), {
    onMutate: async (newAcceptedValue: boolean) => {
      await queryClient.cancelQueries(key);
      const previousAcceptedState = queryClient.getQueryData<CRUser>(key)?.accepted_terms ?? false;
      queryClient.setQueryData<CRUser>(
        key,
        (previousUser) =>
          ({
            ...previousUser,
            accepted_terms: newAcceptedValue,
          } as CRUser),
      );
      return { previousAcceptedState };
    },
    onError: (_err, _newAcceptedState, context) => {
      context &&
        queryClient.setQueryData<CRUser>(
          key,
          (previousUser) =>
            ({
              ...previousUser,
              accepted_terms: context.previousAcceptedState,
            } as CRUser),
        );
    },
  });
};

export const useInviteUsers = () => {
  return useMutation((params: InviteUserParams) => inviteUsers(params));
};

export const useResendEmailConfrimation = () => {
  return useMutation(() => resendVerificationEmail());
};

export const useUpdateUserProfile = () => {
  return useMutation((params: Partial<UserProfileParams>) => updateUserProfile(params));
};
