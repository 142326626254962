import dayjs from 'dayjs';

import { HiddenTag } from '../tag';
import { TimePresetKeyType, TimeRangesType } from '../time-selection';

import { ParentTag } from './folder';

import { TimeSelection } from '@controlrooms/models';

export enum ViewType {
  MONITOR = 'monitor',
  ANALYZE = 'analyze',
}

export interface PersistView {
  user_id?: number;
  view_id?: string;
  view: View;
  shared?: boolean;
  ensemble_family_id?: string;
}

export class PersistTimeSelection {
  startTime: string;
  endTime: string;
  timezone: string;
  streamingTimeInSeconds: number | undefined;
  timeRange?: TimeRangesType;
  timePreset?: TimePresetKeyType;

  constructor(
    startTime: string,
    endTime: string,
    timezone: string,
    streamingTimeInSeconds: number | undefined,
    timeRange: TimeRangesType | undefined,
    timePreset: TimePresetKeyType | undefined,
  ) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.timezone = timezone;
    this.streamingTimeInSeconds = streamingTimeInSeconds;
    this.timeRange = timeRange;
    this.timePreset = timePreset;
  }

  public static ofTimeSelection(init: TimeSelection) {
    return new PersistTimeSelection(
      init.startTime.toISOString(),
      init.endTime.toISOString(),
      init.timezone,
      init.streamingTimeInSeconds,
      init.timeRange,
      init.timePreset,
    );
  }

  public static toTimeSelection(init: PersistTimeSelection) {
    return new TimeSelection(
      dayjs(init.startTime),
      dayjs(init.endTime),
      init.timezone,
      init.streamingTimeInSeconds,
      init.timeRange,
      init.timePreset,
    );
  }
}

export interface View {
  pinnedTags?: ParentTag[];
  hiddenTags?: HiddenTag[];
  type: ViewType;
  timeSelection: PersistTimeSelection;
  selectedFolders: number[];
  ensemble_family_id?: string;
}

export interface MonitorView extends View {
  type: ViewType.MONITOR;
  severityFilter?: number;
  showMonitorLimits?: boolean;
}

export interface AnalyzeView extends View {
  type: ViewType.ANALYZE;
  pinnedTags: ParentTag[];
  hiddenTags?: HiddenTag[];
  showAnalyzeLimits?: boolean;
}

interface SavedView {
  name: string;
  id?: string;
}

export interface DeleteView {
  view_id: string;
  user_id: number;
}

export interface MonitorSavedView extends MonitorView, SavedView {}
export interface AnalyzeSavedView extends AnalyzeView, SavedView {}
